const masterCheckbox = document.querySelector("#js--select_employees");

document.addEventListener("click", event => {
  // If a child checkbox is clicked, add/remove them from localstorage.
  const el = event.target;

  if (el.classList.contains("js--select_employee")) {
    const profileId = el.parentNode.parentNode.dataset.link.split("/")[3];
    const profiles = JSON.parse(localStorage.employees || "[]");
    let newProfiles = [...profiles, profileId];

    if (!el.checked && profiles.includes(profileId)) {
      newProfiles = newProfiles.filter((_, i) => i === profileId);
    }

    localStorage.employees = JSON.stringify(newProfiles);
  }

  // If the master checkbox is clicked, update child checkboxes.
  if (el === masterCheckbox) {
    const checkboxes = document.querySelectorAll(".js--select_employee");
    checkboxes.forEach(checkbox => { checkbox.checked = el.checked })

    const profiles = [];
    if (el.checked) {
      checkboxes.forEach(checkbox => profiles.push(checkbox.parentNode.parentNode.dataset.link.split('/')[3]));
    }

    localStorage.employees = JSON.stringify(profiles);
  }

  // If the file export button is clicked, reset profiles, checkboxes and export the file
  if (el.id === "js--export_employees-btn" && localStorage.employees) {
    const profiles = JSON.parse(localStorage.employees || "[]");

    if (profiles.length) {
      const data = profiles.map(profile => `profile_ids[]=${profile}&`).join("");
      const checkboxes = document.querySelectorAll(".js--select_employee");

      window.open(`/v2/medewerkers/export.xlsx?${data}`, "_blank");

      localStorage.employees = "[]";
      checkboxes.forEach(checkbox => { checkbox.checked = false });
      masterCheckbox.checked = false;
    }
  }
});

/**
 * If employees exist in local storage, and the master checkbox exists.
 */
if (localStorage.employees && masterCheckbox) {
  const employeeList = JSON.parse(localStorage.employees || "[]");

  // For every localstorage employee, check its corresponding checkbox.
  employeeList.forEach(profile => {
    document.querySelector(`[data-link='/v2/medewerkers/${profile}/gegevens'] > td:first-child > input`).checked = true;
  });

  // If all the boxes are checked, check the master checkbox.
  if (employeeList.length === document.querySelectorAll(".js--select_employee").length) {
    masterCheckbox.checked = true;
  }
}