import "./filters.scss";

function toggleFilters(tab) {
  const filters = document.querySelector(`#${tab}_filters`);
  if (filters.classList.contains("active")) {
    filters.classList.remove("active");
  } else {
    filters.classList.add("active");
  }
}

window.addEventListener("click", event => {
  if (event.target.classList.contains("toggle_filters")) {
    toggleFilters(event.target.dataset.tab);
  }
  if (event.target.classList.contains("close_filters")) {
    toggleFilters(event.target.dataset.tab);
  }
});
