import "./navbar.scss";

function toggleMenu() {
  const sidebar = document.querySelector(".sidebar");
  if (sidebar.offsetWidth === 250) {
    sidebar.style.width = "100%";
    sidebar.style.marginLeft = "0px";
    document.querySelector("#menu-icon").innerHTML = "menu_open";
  } else {
    sidebar.style.width = "250px";
    sidebar.style.marginLeft = "-250px";
    document.querySelector("#menu-icon").innerHTML = "menu";
  }
}

if (document.querySelector("#menu-icon") !== null) {
  document.querySelector("#menu-icon").addEventListener("click", () => {
    toggleMenu();
  });
}

const scrollableNavbars = document.getElementsByClassName("scrollable_tabs");
for (let i = 0; i < scrollableNavbars.length; i++) {
  if (scrollableNavbars[i].getElementsByClassName("nav-item active")[0] !== undefined) {
    scrollableNavbars[i].getElementsByClassName("nav-item active")[0].scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center"
    })
  }
};
