function repositionFAB() {
  const pagination = document.querySelector(".pagination");
  const fab = document.querySelector("#red_button");
  if (pagination !== null && fab !== null) {
    const mediaQuerie = window.matchMedia("(max-width: 575px)");
    const paginationPositionBottom = pagination.getBoundingClientRect().y;
    const fabPositionBottom = fab.getBoundingClientRect().y;

    if (mediaQuerie.matches) {
      if (
        pagination !== null &&
        fab !== null &&
        paginationPositionBottom - fabPositionBottom < 50
      ) {
        fab.style.bottom = "68px";
      } else {
        fab.style.bottom = "30px";
      }
    } else if (
      pagination !== null &&
      fab !== null &&
      paginationPositionBottom - fabPositionBottom < 50
    ) {
      fab.style.bottom = "125px";
    } else {
      fab.style.bottom = "85px";
    }
  }
}

window.addEventListener("scroll", function() {
  repositionFAB();
});

window.addEventListener("touchmove", function() {
  repositionFAB();
});

$(document).ready(function(){
  $('[data-toggle="tooltip"]').tooltip();
  $('#otpModal').modal('show');
  if (sessionStorage.getItem("click_fab")) {
    sessionStorage.removeItem("click_fab")
    document.getElementById("red_button").click();
  }
});

document.addEventListener("click", event => {
  if (event.target.id === "js--new_furlough") {
    document.getElementById("js--link_to_saldo").click();
    sessionStorage.setItem("click_fab", true)
  };
});
