import { Calendar } from "@fullcalendar/core";

import dayGridPlugin from "@fullcalendar/daygrid";

import timeGridPlugin from "@fullcalendar/timegrid";

import listPlugin from "@fullcalendar/list";

import nlLocale from "@fullcalendar/core/locales/nl";

import interactionPlugin from "@fullcalendar/interaction";

import bootstrapPlugin from "@fullcalendar/bootstrap";

import "@fortawesome/fontawesome-free/css/all.css";

import "./calendar.scss";

function renderCalendar() {
  const calendarEl = document.getElementById("calendar");

  if (calendarEl !== null) {
    const currentUrl = window.location.search;
    if (currentUrl.includes("case_mutation_id")) {
      const caseMutationId = currentUrl.substring(
        currentUrl.lastIndexOf("=") + 1
      );
      const url = `/v2/saldo/${caseMutationId}`;
      alert();
      // eslint-disable-next-line no-undef
      Rails.ajax({
        type: "GET",
        url,
        dataType: "json"
      });
    }

    // eslint-disable-next-line no-inner-declarations
    function mobileCheck() {
      if (window.innerWidth >= 768) {
        return true;
      }
      return false;
    }

    // eslint-disable-next-line no-inner-declarations
    function headerToolbar() {
      if (window.innerWidth >= 768) {
        return {
          start: "prev,next today",
          center: "title",
          end: "dayGridMonth,timeGridWeek,timeGridDay listYear"
        };
      }
      return {
        start: "dayGridMonth,timeGridWeek,timeGridDay",
        end: "title prev,next"
      };
    }

    const calendar = new Calendar(calendarEl, {
      plugins: [
        dayGridPlugin,
        interactionPlugin,
        timeGridPlugin,
        listPlugin,
        bootstrapPlugin
      ],
      themeSystem: "bootstrap",
      initialView: mobileCheck() ? "dayGridMonth" : "timeGridDay",
      nowIndicator: true,
      eventOverlap: true,
      slotEventOverlap: true,
      contentHeight: "692px",
      weekNumbers: true,
      locale: nlLocale,
      slotMinTime: "05:00:00",
      slotMaxTime: "22:30:00",
      slotDuration: "00:30:00",
      slotLabelInterval: "00:30:00",
      scrollTime: "05:00:00",
      slotLabelFormat: {
        hour: "2-digit",
        minute: "2-digit",
        omitZeroMinute: false,
        meridiem: "short"
      },
      displayEventTime: false,
      eventDisplay: "block",
      eventTimeFormat: {
        hour: "2-digit",
        minute: "2-digit",
        omitZeroMinute: false
      },
      headerToolbar: headerToolbar(),
      dayMaxEventRows: 5,
      moreLinkClick: "day",
      // eslint-disable-next-line no-use-before-define
      events: `/v2/pages/events.json?department=${departmentFilterValue}&schedule_free=${scheduleFreeFilterValue}`,
      eventClick(info) {
        // eslint-disable-next-line no-undef
        Rails.ajax({
          url: `/v2/saldo/${info.event.id}`,
          type: "get",
          dataType: "script"
        });
      },
      eventDidMount(info) {
        if (info.view.type === "listYear") {
          const dotEl = info.el.getElementsByClassName("fc-list-event-dot")[0];
          if (
            info.event.extendedProps.status === "In behandeling" &&
            info.event.extendedProps.owner
          ) {
            dotEl.style.borderColor = "#ffc107";
          } else if (
            info.event.extendedProps.status === "In behandeling" &&
            !info.event.extendedProps.owner
          ) {
            dotEl.style.borderColor = "#fff3cd";
            // eslint-disable-next-line no-param-reassign
            info.el.firstElementChild.id = info.event.extendedProps.mutationId;
            // eslint-disable-next-line no-param-reassign
            info.el.firstElementChild.style.color = "#444446";
          } else if (
            info.event.extendedProps.status === "Goedgekeurd" &&
            info.event.extendedProps.owner
          ) {
            dotEl.style.borderColor = "#28a745";
            // eslint-disable-next-line no-param-reassign
            info.el.firstElementChild.id = info.event.extendedProps.mutationId;
          } else if (info.event.extendedProps.status === "Goedgekeurd") {
            // eslint-disable-next-line no-param-reassign
            info.el.firstElementChild.style.color = "#444446";
            // eslint-disable-next-line no-param-reassign
            info.el.firstElementChild.id = info.event.extendedProps.mutationId;
            // eslint-disable-next-line no-param-reassign
            dotEl.style.borderColor = "#d4edda";
          }
        }
        if (
          info.event.extendedProps.status === "In behandeling" &&
          info.view.type !== "listYear" &&
          info.event.extendedProps.owner
        ) {
          // eslint-disable-next-line no-param-reassign
          info.el.style.backgroundColor = "#ffc107";
          // eslint-disable-next-line no-param-reassign
          info.el.style.borderColor = "#ffc107";
          // eslint-disable-next-line no-param-reassign
          info.el.firstElementChild.id = info.event.extendedProps.mutationId;
        } else if (
          info.event.extendedProps.status === "In behandeling" &&
          info.view.type !== "listYear" &&
          !info.event.extendedProps.owner
        ) {
          // eslint-disable-next-line no-param-reassign
          info.el.style.backgroundColor = "#fff3cd";
          // eslint-disable-next-line no-param-reassign
          info.el.firstElementChild.id = info.event.extendedProps.mutationId;
          // eslint-disable-next-line no-param-reassign
          info.el.style.borderColor = "#fff3cd";
          // eslint-disable-next-line no-param-reassign
          info.el.firstElementChild.style.color = "#444446";
        } else if (
          info.event.extendedProps.status === "Goedgekeurd" &&
          info.view.type !== "listYear" &&
          info.event.extendedProps.owner
        ) {
          // eslint-disable-next-line no-param-reassign
          info.el.style.backgroundColor = "#28a745";
          // eslint-disable-next-line no-param-reassign
          info.el.firstElementChild.id = info.event.extendedProps.mutationId;
          // eslint-disable-next-line no-param-reassign
          info.el.style.borderColor = "#28a745";
        } else if (
          info.event.extendedProps.status === "Goedgekeurd" &&
          info.view.type !== "listYear"
        ) {
          // eslint-disable-next-line no-param-reassign
          info.el.firstElementChild.id = info.event.extendedProps.mutationId;
          // eslint-disable-next-line no-param-reassign
          info.el.firstElementChild.style.color = "#444446";
          // eslint-disable-next-line no-param-reassign
          info.el.style.backgroundColor = "#d4edda";
          // eslint-disable-next-line no-param-reassign
          info.el.style.borderColor = "#d4edda";
        } else {
          // eslint-disable-next-line no-param-reassign
          info.el.firstElementChild.style.color = "#444446";
          // eslint-disable-next-line no-param-reassign
          info.el.style.backgroundColor = "#ced8da";
          // eslint-disable-next-line no-param-reassign
          info.el.style.borderColor = "#ced8da";
        }
      }
    });
    calendar.render();
  }
}

document.addEventListener("DOMContentLoaded", () => {
  renderCalendar();
});

const departmentFilter = document.querySelector("#events_department_filter");
let departmentFilterValue = "";
if (departmentFilter !== null) {
  departmentFilter.addEventListener("change", () => {
    departmentFilterValue = departmentFilter.value;
    renderCalendar();
  });
}

const scheduleFreeFilter = document.querySelector("#events_schedule_free_filter");
let scheduleFreeFilterValue;
if (scheduleFreeFilter !== null) {
  scheduleFreeFilter.addEventListener("change", () => {
    scheduleFreeFilterValue = scheduleFreeFilter.checked;
    renderCalendar();
  });
}
