document.addEventListener("change", event => {
  if (event.target.classList.contains("click_link_on_change")) {
    if (document.getElementById("container-loader") !== undefined && document.getElementById("container-loader") !== null) {
      document.getElementById("overworks_table").innerHTML = "";
      document.getElementById("overworks_filters_container").classList.remove("d-xl-flex", "d-block");
      document.getElementById("overworks_filters_container").classList.add("d-none");
      document.getElementById("overworks_export_button").classList.remove("d-flex");
      document.getElementById("overworks_export_button").classList.add("d-none");
      document.getElementById("container-loader").classList.remove("d-none");
      document.getElementById("container-loader").classList.add("d-flex");
    }
  }
});