const toolType =
  document.getElementById("tool_contract_tool_type_id") !== null
    ? "tool_contract_tool_type"
    : "vehicle_tool_tool_type";
const amountFieldPresent =
  document.getElementById("amount_field") !== null &&
  document.getElementById("amount_field") !== undefined;

function getTools(value) {
  const url = new URL(document.URL);
  const path = url.pathname.split("/");
  path.pop();
  url.pathname = path.join("/");
  if (value.length > 0) {
    Rails.ajax({
      url: `${url}/available_tools`,
      type: "GET",
      data: `tool=${value}`,
      dataType: "script"
    });
  }
}

function toolClickHandler(tool) {
  if (tool.parentNode.classList.contains("alert-success")) {
    tool.parentNode.classList.remove("alert-success");
    document.getElementById(`${toolType}_id`).value = "";
  } else {
    document
      .querySelectorAll(".tool_row")
      .forEach(row => row.classList.remove("alert-success"));
    tool.parentNode.classList.add("alert-success");
    document.getElementById(`${toolType}_id`).value = tool.parentNode.id;
    if (amountFieldPresent) {
      document
        .getElementById("tool_contract_amount")
        .setAttribute("max", tool.parentNode.lastElementChild.innerHTML);
    }
  }
}

function toggleDisplayAmountField(target) {
  if (target.options[target.selectedIndex].value === "WorkClothing") {
    document.getElementById("amount_field").classList.remove("d-none");
  } else {
    document.getElementById("amount_field").classList.add("d-none");
  }
}

function updateLink(checkboxes) {
  const arr = [];
  checkboxes.forEach(checkbox => {
    if (checkbox.checked) {
      arr.push(checkbox.id);
    }
  });
  const exportBtnLink = document.getElementById("js--generatePdfBtn")
    .parentElement;
  if (arr.length === 0) {
    exportBtnLink.firstElementChild.classList.add("d-none");
  } else {
    let str = "";
    arr.forEach(id => {
      str += `tool_contract_ids[]=${id}&`;
    });
    exportBtnLink.setAttribute(
      "href",
      `${window.location.pathname}/export.pdf?${str}`
    );
    exportBtnLink.firstElementChild.classList.remove("d-none");
  }
}

const checkboxes = document.querySelectorAll(".js--addToPdf");
if (checkboxes !== null) {
  checkboxes.forEach(checkbox =>
    checkbox.addEventListener("change", () => {
      updateLink(checkboxes);
    })
  );
}

document.addEventListener("change", event => {
  if (["tool_contract_tool_type", "vehicle_tool_tool_type"].includes(event.target.id)) {
    if (amountFieldPresent) {
      toggleDisplayAmountField(event.target);
    }
    getTools(event.target.value);
  }
});

document.addEventListener("click", event => {
  if (event.target.classList.contains("tool_col")) {
    toolClickHandler(event.target);
  }
});
