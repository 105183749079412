let dragElement;
let dragOffset = 0;
let dragging = false;

document.addEventListener("mousemove", (e) => {
  if (dragging) {
    const relativeHeight = (((e.y - 10) / window.innerHeight) * 100);
    const relativeWidth = (((e.x - dragOffset) / window.innerWidth) * 100);

    dragElement.style.left = `${relativeWidth}%`;
    dragElement.style.top = `${relativeHeight}%`;
  }
});

document.body.addEventListener("mousedown", (e) => {
  let el = e.target;
  if (el.classList.contains("draggable-modal")) {
    const left = ((Number.parseInt(el.style.left) || 0) / 100) * window.innerWidth;
    dragOffset = e.x - left;
    dragElement = el;
    dragging = true;
  }
});

document.body.addEventListener("mouseleave", () => {
  dragging = false;
});

document.body.addEventListener("mouseup", () => {
  dragging = false;
});