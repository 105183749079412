import Rails from "@rails/ujs";

import "./new_mutation.scss";

function mutationCalendarClicked(date) {
  const profileId = document.getElementById("mutation_details").dataset.profile;
  const mutationId = document.getElementById("mutation_details").dataset.mutationid;
  const type = document.getElementById("mutation_details").dataset.mutationtype;

  // check if booking_year is still being used or remove it
  const calendarTitle = document.getElementsByClassName("calendar-title")[0].innerHTML;
  const bookingYear = calendarTitle.substr(
    calendarTitle.length - 4,
    calendarTitle.length
  );

  let currentUrl = window.location.pathname;
  if (currentUrl.includes('/artsbezoek') || currentUrl.includes('/bijzonder-verlof') || currentUrl.includes('/aanvullend-geboorte-verlof')) {
    if (currentUrl.includes('/v2/medewerkers')) {
      currentUrl = `/v2/medewerkers/${profileId}/saldo`;
    } else {
      currentUrl = `/v2/saldo`;
    }
  }

  const url = `${currentUrl}/${mutationId}/validate`;

  Rails.ajax({
    type: "POST",
    url,
    data: `date=${date}&profile_id=${profileId}&booking_year=${bookingYear}&type=${type}`,
    dataType: "json"
  });
}

function click_link_within_option(link) {
  Rails.ajax({
    url: link,
    type: "GET",
    contentType: 'application/json'
  });
}

document.addEventListener("click", event => {
  if (event.target.classList.contains("caseMutationCalendar")) {
    mutationCalendarClicked(event.target.id);
  }
});

document.addEventListener("change", event => {
  if (event.target.classList.contains("click_link_within_option")) {
    click_link_within_option(event.target.options[event.target.options.selectedIndex].value);
  }
});
