
function showAssignChecklist() {
  document.getElementById('show_assign_checklist--js').parentNode.classList.add('d-none');
  document.getElementById('close_assign_checklist--js').parentNode.classList.remove('d-none');
  document.getElementById('assign_form').classList.remove('hide');
  document.getElementById('assign_form').style.borderRight = '1px solid #c1c1c1';
}

function hideAssignChecklist() {
  document.getElementById('show_assign_checklist--js').parentNode.classList.remove('d-none');
  document.getElementById('assign_form').classList.add('hide');
}

document.addEventListener("click", event => {
  if (event.target.id == 'show_assign_checklist--js') {
    showAssignChecklist();
  } else if (event.target.id == 'close_assign_checklist--js') {
    hideAssignChecklist();
  }
});