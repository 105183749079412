const licensePlateField = document.getElementById('vehicle_license_plate');
if (licensePlateField !== undefined && licensePlateField !== null) {
  validateInput(licensePlateField.value);
  document.addEventListener("keyup", event => {
    if (event.target.id === "vehicle_license_plate") {
      validateInput(event.target.value);
    }
  });
}

function validateInput(licensePlate) {
  if (licensePlate.length >= 6) {
    getDataFromRDW(licensePlate);
  }
}

function getDataFromRDW(plate) {
  const licensePlate = plate.replace(/-/g, "").toUpperCase();
  const url = `https://opendata.rdw.nl/resource/m9d7-ebf2.json?kenteken=${licensePlate}`
  const otherParams = {
    headers: {
      "X-App-Token": "KaaprFkBfHeQIgnDp7lDvfqWH"
    },
    method: "GET"
  };
  fetch(url, otherParams)
  .then(data => { return data.json() } )
  .then(res => { fillVehicleForm(res[0]) } )
  .catch(error => { } )
}

function fillVehicleForm(data) {
  console.log(data)
  document.getElementById("vehicle_license_plate").value = data.kenteken;
  document.getElementById("vehicle_brand").value = data.merk;
  document.getElementById("vehicle_kind").value = data.voertuigsoort;
  document.getElementById("vehicle_trade_name").value = data.handelsbenaming;
  document.getElementById("vehicle_catalog_price").value = data.catalogusprijs;
  document.getElementById("rdwExtraData").innerHTML = "";
  Object.entries(data).forEach(([key, val]) => {
    const row = document.createElement("tr");
    const columnKey = document.createElement("td");
    const textKey = document.createTextNode(key);
    columnKey.appendChild(textKey);
    const columnValue = document.createElement("td");
    const textValue = document.createTextNode(val);
    columnValue.appendChild(textValue);
    row.appendChild(columnKey);
    row.appendChild(columnValue);
    document.getElementById("rdwExtraData").append(row);
  });
}