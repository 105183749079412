function submitUploadDocumentForm(target) {
  const form = document.getElementById("uploadDocumentForm");
  const formData = new FormData(form);
  const url = window.location.href.slice(0, -4);
  Rails.ajax({
    url: url,
    type: "POST",
    dataType: "script",
    data: formData,
    contentType: false,
    processData: false,
    success() {
      window.history.back();
    },
    error: function(response) {
      document.getElementById('name_error').innerHTML = response
    }
  });
}

document.addEventListener("click", event => {
  if (event.target.id == "delete-doc-icon") {
    let href = window.location.href;
    if (document.getElementById("delete-doc-link") === null) {
      let position = href.lastIndexOf("?");
      document.getElementById("delete-doc-for-profile-link").setAttribute("href", `${href.substring(0, position)}/${event.target.dataset.document}/${href.substring(position)}`)
    } else {
      document.getElementById("delete-doc-link").setAttribute("href", `${href}/documents/${event.target.dataset.document}`)
    }
    document.getElementById("doc-name").innerHTML = event.target.dataset.name;
  }
  if (event.target.id === 'downloadDoc') {
    document.getElementById('downloadDocument').click();
    $('#otpModal').modal('hide');
  }
  $('#documents_authorization_form').on('submit', function() {
    $('#otpModal').modal('hide');
  });
  if (event.target.id === "uploadDocument") {
    submitUploadDocumentForm(event.target);
  }
});

$(".custom-file-input").on("change", function() {
  var fileName = $(this).val().split("\\").pop();
  $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
});
