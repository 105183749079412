document.addEventListener("focusout", event => {
  if (event.target.classList.contains("click_link_on_focusout")) {
    document.getElementById(`link_to_${event.target.id}`).click();
  }
});

document.addEventListener("keyup", event => {
  if (event.target.classList.contains("click_link_on_onkeyup")) {
    document.getElementById(`link_to_${event.target.id}`).click();
  }
});

document.addEventListener("click", event => {
  if (event.target.classList.contains("click_link_on_click")) {
    document.getElementById(`link_to_${event.target.dataset.link}`).click();
  }
  if (event.target.classList.contains("clickable-row")) {
    window.location = event.target.dataset.link;
  }
  if (event.target.parentNode.dataset.link !== undefined) {
    window.location = event.target.parentNode.dataset.link
  }
  if (event.target.parentNode.dataset.linkRemote !== undefined) {
    window.open(event.target.parentNode.dataset.linkRemote, '_blank')
  }
});

document.addEventListener("change", event => {
  if (event.target.classList.contains("click_link_on_change")) {
    if (event.target.dataset.reset) {
      document.querySelectorAll(`.${event.target.dataset.reset}`)[0].options.selectedIndex = '0';
    }
    document.getElementById(`link_to_${event.target.id}`).click();
  }
});
