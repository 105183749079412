if (document.getElementsByClassName("js--reload-page").length > 0) {
  Rails.ajax({
    type: "GET",
    url: window.location,
    dataType: "script"
  });
}

window.addEventListener("load", () => {
  if (document.querySelector("#js--illness_chart")) {
    const url = `/v2/rapportages/ziekmeldingen/illness_data${window.location.search}`;

    Rails.ajax({
      type: "GET",
      url,
      dataType: "js"
    });
  }
});