function emptyScheduleDay(event) {
  const index = event.target.dataset.index;

  document.getElementById(`start_field_${index}`).value = '';
  document.getElementById(`end_field_${index}`).value = '';
  document.getElementById(`break_field_${index}`).value = '';
}

document.addEventListener("click", event => {
  if (event.target.classList.contains("empty_schedule")) {
    emptyScheduleDay(event)
  }
});
