$("#otpModal").on('shown.bs.modal', function () {
  $("#otp_attempt").focus();
});

function paste() {
  const otpAttempt = document.querySelector("#otp_attempt");
  navigator.clipboard.readText().then(text => otpAttempt.value = text);
}

const otpAttemptField = document.querySelector("#pasteCode");
if (otpAttemptField !== null) {
  otpAttemptField.addEventListener("click", paste);
}