import "./table.scss";

function clickRemoteLink(e) {
  document.querySelector(`#link_to_${e.dataset.object}`).click();
}

document.addEventListener("click", function tableRowClicked(event) {
  if (event.target.parentNode.classList.contains("remote_row_link")) {
    clickRemoteLink(event.target.parentNode);
  }
});
