import "./profile_header.scss";

function restyleHeader() {
  const header = document.querySelector("#profile_header");
  const card = document.querySelector("#profile_card");
  const userImage = document.querySelector("#user_image");
  const objectsToHide = document.querySelectorAll(".hideOnScroll");
  const profileInfo = document.querySelector("#profile_info");
  const profileToday = document.querySelector("#profile_today");
  if (header !== null) {
    const cardPositionTop = card.getBoundingClientRect().y - 16;
    if (cardPositionTop < 0 && header.style.position !== "sticky") {
      // scrolling down, stick items
      header.style.position = "sticky";
      header.style.top = "16px";
      header.style.width = "100%";
    } else if (cardPositionTop < -57 && userImage.style.width !== "50px") {
      // scrolling further down, hide items
      objectsToHide.forEach(object => {
        object.style.transition = "400ms";
        object.style.opacity = 0;
        object.style.display = "none";
      });
      profileInfo.style.maxHeight = "82px";
      profileInfo.style.alignItems = "center";
      userImage.style.width = "50px";
      profileToday.style.display = "flex";
      profileToday.style.opacity = "1";
      profileToday.style.alignItems = "center";
      profileToday.style.height = "100%";
    } else if (cardPositionTop > 20) {
      // scrolling to top, visible items
      header.style.position = "static";
      header.style.top = "0";
      profileInfo.style.maxHeight = "fit-content";
      profileInfo.style.alignItems = "initial";
      profileToday.style.display = "block";
      profileToday.style.opacity = "1";
      userImage.style.width = "100px";
      objectsToHide.forEach(object => {
        object.style.transition = "400ms";
        object.style.display = "flex";
        object.style.opacity = 1;
      });
    }
  }
}

window.addEventListener("scroll", () => {
  restyleHeader();
});

window.addEventListener("touchmove", () => {
  restyleHeader();
});
