document.addEventListener("click", event => {
  if (event.target.classList.contains('sortable-column')) {
    let columnIndex = event.target.dataset.index;
    let classlist = event.target.classList;
    let rows = Array.from(document.getElementsByTagName('tr'));
    let values = [];

    collectValues(columnIndex, rows, values);
    sortValues(values, classlist);
    insertNewRows(values, sortDirection(event));
  }
});

function collectValues(columnIndex, rows, values) {
  let curVal = '';

  for (let index = 1; index < rows.length; index++) {
    curVal = rows[index].children[columnIndex].innerHTML;
    values.push({ value: curVal, row: rows[index] });
  }
}

function sortValues(values, classlist) {
  if (classlist.contains('number')) {
    values.sort(sortIntValues);
  } else if (classlist.contains('date')) {
    values.sort(sortDateValues);
  } else if (classlist.contains('text')) {
    values.sort(sortTextValues);
  } else if (classlist.contains('years_and_days')) {
    values.sort(sortYearsAndDaysValues);
  }
}

function emptyTable() {
  document.getElementsByTagName('tbody')[0].innerHTML = '';
}

function insertNewRows(values, direction) {
  emptyTable();
  if (direction === 'desc') {
    for (let idx = 0; idx < values.length; idx++) {
      document.getElementsByTagName('tbody')[0].appendChild(values[idx].row);
    }
  } else {
    for (let idx = values.length - 1; idx > -1; idx--) {
      document.getElementsByTagName('tbody')[0].appendChild(values[idx].row);
    }
  }
}

function sortDirection(event) {
  let direction = event.target.dataset.sort;
  let new_direction = direction === 'asc' ? 'desc' : 'asc';
  event.target.dataset.sort = new_direction;
  return direction;
}

function sortTextValues(a, b) {
  const textA = (a.value + "").toLowerCase();
  const textB = (b.value + "").toLowerCase();

  if (textA < textB) {
    return -1;
  } else if (textA > textB) {
    return 1;
  } else {
    return 0;
  }
}

function sortIntValues(a, b) {
  return sortNumber(a.value, b.value);
}

function sortNumber(a, b) {
  return a - b;
}

function sortDateValues(a, b) {
  let dateA;
  let dateB;
  if (a.value === "-") {
    dateA = Date.parse(monthDateYearFormat("01-01-2200"));
  } else {
    dateA = Date.parse(monthDateYearFormat(a.value));
  }
  if (b.value === "-") {
    dateB = Date.parse(monthDateYearFormat("01-01-2200"));
  } else {
    dateB = Date.parse(monthDateYearFormat(b.value));
  }

  return sortNumber(dateA, dateB);
}

function sortYearsAndDaysValues(a, b) {
  const yearA = a.value.split(' ')[0];
  const yearB = b.value.split(' ')[0];
  const daysA = a.value.split(' ')[3];
  const daysB = b.value.split(' ')[3];

  if (yearA !== yearB) {
    return yearA - yearB;
  } else {
    return daysA - daysB;
  }
}

function monthDateYearFormat(date) {
  return date.substr(0, 5).split('-').reverse().join('-') + '-' + date.substr(6, date.length);
}