import Rails from "@rails/ujs";

document.addEventListener("click", event => {
  if (event.target.classList.contains("saldo_modal")) {
    const profileId = event.target.dataset.profile;
    const genre = event.target.dataset.genre;
    const saldoType = event.target.dataset.saldo;
    const bookingYear = event.target.dataset.year;
    const url = `/v2/medewerkers/${profileId}/saldo/saldo_modal`;

    Rails.ajax({
      type: "GET",
      url,
      data: `genre=${genre}&saldo=${saldoType}&profile_id=${profileId}&booking_year=${bookingYear}`,
      dataType: "json"
    });
  };
});