// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.

// const channels = require.context('.', true, /_channel\.js$/)
// channels.keys().forEach(channels)

(() => {
  const ogOpen = XMLHttpRequest.prototype.open;
  XMLHttpRequest.prototype.open = function (...args) {
    this.addEventListener("load", () => this.responseText === "Je sessie is verlopen. Log opnieuw in." && window.location.reload());
    ogOpen.apply(this, args);
  };
})();