document.addEventListener("click", function(event) {
  if (event.target.classList.contains("remove") || event.target.parentNode.classList.contains("remove")) {
    const typeElement = document.getElementById("js--confirmDeleteType");
    const linkElement = document.getElementById("js--confirmDeleteLink");
    if (event.target.dataset.type === undefined) {
      typeElement.innerHTML = event.target.parentNode.dataset.type;
      linkElement.setAttribute("href", event.target.parentNode.dataset.confirmDeleteLink);
    } else {
      typeElement.innerHTML = event.target.dataset.type;
      linkElement.setAttribute("href", event.target.dataset.confirmDeleteLink);
    }
    $("#js--confirmDeleteModal").modal();
  }
})