import Rails from "@rails/ujs";

import "./new_work_registration.scss";

function enterDateIntoDateField(date) {
	const year = date.slice(6, 10);
	const month = date.slice(3, 5);
	const day = date.slice(0, 2);
	const dateFormat = `${year}-${month}-${day}`;
	document.getElementById('start_datefield').value = dateFormat;
}

function workRegistrationCalendarClicked(date) {
	enterDateIntoDateField(date);
}

document.addEventListener("click", event => {
  if (event.target.classList.contains("workRegistrationCalendar")) {
    workRegistrationCalendarClicked(event.target.id);
  }
});
